@import "../../variables/variables.scss";
.upcoming-events {
  text-align: center;
  h2 {
    @include h2-style;
  }
}
.event-card {
  margin-bottom: 30px;
  overflow: hidden;
  transition: ease-in-out 0.3s;
}

.event-img {
  overflow: hidden;
  transition: ease-in-out 0.3s;
  img {
    object-fit: cover;
    width: 75%;
    transition: ease-in-out 0.3s;
    &:hover {
      transform: scale(1.1);
      transition: ease-in-out 0.3s;
    }
  }
}
.event-time {
  padding: 0px;
  font-size: 14px;
  display: flex;
  .date,
  .location {
    width: 50%;
    padding: 10px;
    background: $primarycolor;
    color: $white;
    svg {
      margin-right: 8px;
    }
  }
  .location {
    background-color: $seccondarycolor;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    svg {
      margin-right: 10px;
    }
  }
}

.view-events button {
  @include button-style;
}
