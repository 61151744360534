@import "../../variables/variables.scss";
.info-sec img {
	width: 400px;
}
.info-box {
	border: 2px solid gray;
	a {
		color: #ffffff;
		text-decoration: none;
		font-weight: 600;
		background: $primarycolor;
		padding: 10px;
		display: block;
		&:hover {
			background: $seccondarycolor;
			color: $white;
		}
	}
}
.rule-by-age {
	margin-top: 50px;
	text-align: center;
	padding: 30px;
}
.rule-box {
	margin: 20px 0px;
	border: 2px solid $primarycolor;
	position: relative;
	img {
		width: 100%;
		object-fit: cover;
	}
	h5 {
		margin-bottom: 20px;
		border-bottom: 2px solid $primarycolor;
		padding: 10px;
		background: $primarycolor;
		color: #fff;
	}
	span.download-pdf {
		width: 50px;
		height: 50px;
		position: absolute;
		top: -5px;
		right: 0;
		font-size: 30px;
		color: #fff;
	}
}
