@import "../../variables/variables.scss";
.signup-form {
  h2 {
    @include h2-style;
    text-align: center;
  }

  .signup {
    max-width: 900px;
    margin: auto;
    padding: 20px;
    box-shadow: 0px 0px 30px 4px #e1e1e1;
    border-radius: 10px;
    input,
    select {
      width: 100%;
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #dedede;
      border-radius: 4px;
      text-align: left;
      height: 54px;
      background-color: white;
      &:focus-visible {
        border: 2px solid #000;
      }
    }
    textarea {
      height: 100px;
      resize: none;
      margin-bottom: 20px;
      &:focus {
        box-shadow: none;
        border: 2px solid #000;
      }
    }
    .submit-btn {
      @include button-style;
      width: 100%;
      margin-top: 20px;
      padding: 10px;
    }
    .error-msg {
      color: red;
    }

    .imagePrev {
      width: 230px;
      height: 130px;
      border: none;
      outline: none;
      margin: 10px;
    }
  }
}

form.signup.admin {
  width: 100%;
  max-width: 100%;
}

/* media query style start here 	*/
// @media (max-width: $medium-device) {
// 	.signup-form .signup {
// 		padding: 30px;
// 	}
// }
input#file:after {
  content: "Choose File";
  background: #6d1e11;
  position: absolute;
  top: 0px;
  width: 118px;
  height: 100%;
  display: flex;
  left: 0;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}
input#file {
  position: relative;
}
