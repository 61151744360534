$lightbg: #fcefdc;
$primarycolor: #6d1e11;
$seccondarycolor: #875e20;
$white: #ffffff;
$black: #000000;
$extra-larger: 1024px;
$larger-device: 991px;
$medium-device: 767px;
$small-device: 545px;
$section-padding: 45px 0px;

@mixin button-style {
  padding: 15px 40px;
  text-transform: uppercase;
  font-weight: 600;
  border: 2px solid $seccondarycolor;
  color: $seccondarycolor;
  background-color: $white;
  border-radius: 10px;
  &:hover {
    background-color: $seccondarycolor;
    color: $white;
  }
}

@mixin h2-style {
  text-transform: uppercase;
  background: -webkit-linear-gradient($primarycolor, $seccondarycolor);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 40px;
}

.section-padding {
  padding: $section-padding;
}
