@import "../../variables/variables.scss";
.header-sec {
  img {
    width: 60px;
    height: 40px;
    margin: 5px;
    cursor: pointer;
  }

  #usaflag {
    margin-left: 30px;
  }

  background-color: $lightbg;
  .row {
    align-items: center;
  }
  .navbar {
    a.nav-link {
      color: $primarycolor;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 14px;
      padding-left: 14px;
      padding-right: 14px;
    }
  }
  .dropdown-menu.show {
    padding: 0px;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    background: $primarycolor;
  }
  .dropdown-item:hover {
    border-radius: 5px;
  }
}
.userimage {
  width: 45px;
  height: 45px;
  border: 3px solid $seccondarycolor;
  border-radius: 100%;
  float: right;
  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
}
span.navbar-text {
  margin-left: 30px;
}

.row.top-header a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  // margin-left: 10px;
  color: #fff;
  padding: 6px 12px;
}
button.logout-btn {
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
}

/* media query style start here 	*/
@media (max-width: $extra-larger) {
  .header-sec .navbar a.nav-link {
    font-size: 11px;
  }
}
@media (max-width: $larger-device) {
  .navbar-nav {
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    padding: 0px;
    background-color: $primarycolor;
    left: 0;
    border-left: 1px solid #fcefdc;
    border-right: 1px solid #fcefdc;
    border-top: 1px solid #fcefdc;
  }
  .header-sec .navbar a.nav-link {
    color: $white;
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    padding: 10px;
    border-bottom: 1px solid #fcefdc;
  }
  .nav-item.dropdown {
    width: 100%;
  }
  #basic-nav-dropdown .dropdown-menu {
    text-align: center !important;
  }
  .nav-item.dropdown.show {
    border-bottom: 1px solid #fcefdc;
    padding-bottom: 3px;
  }
  .nav-item.show.dropdown #basic-nav-dropdown.nav-link {
    border-bottom: none;
  }
  .dropdown-menu.show {
    margin: 0 5px;
    text-align: center;
  }
  .justify-content-end.user.navbar-collapse.collapse.show {
    position: absolute;
    right: 80px;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
}

@media (max-width: $medium-device) {
  .header-sec {
    .container {
      position: relative;
    }
    .userimage {
      position: absolute;
      top: 58px;
      right: 80px;
    }
  }
}
@media (max-width: $small-device) {
  .header-sec img {
    width: 200px;
  }
  .header-sec .userimage img {
    width: 100%;
  }
  .header-sec .userimage {
    top: 44px;
  }
}

.top-hdr {
  background: #6d1c0f;
  padding: 10px 0px;
}
.navbar-brand {
  max-width: 200px;
  img {
    width: 100%;
  }
}
