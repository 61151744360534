@import "../../variables/variables.scss";
.our-sponsors {
	h2 {
		@include h2-style;
		text-align: center;
	}
	img.sponsor {
		width: 100%;
		border: 1px solid;
	}
	.sponsor-info {
		align-items: center;
		h3 {
			text-transform: uppercase;
		}
	}
	.row.sponsor-list {
		margin-top: 50px;
	}
	.sponsorbox {
		padding: 30px;
		box-shadow: 0px 0px 30px 6px #e3e3e3;
		border-radius: 15px;
		margin-bottom: 20px;
		img {
			border: none;
		}
	}
}
