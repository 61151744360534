body {
  font-family: "Poppins", sans-serif !important;
}
.App {
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.back-to-top svg {
  background: linear-gradient(313deg, #df8620, #722a14);
  border-radius: 100%;
  color: #fff;
}

.matchesList li {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.errorFormInput {
  border: 2px solid red !important;
}

.waitMessage {
  font-size: 1.1rem;
  color: rgb(230, 0, 0);
}

.button-auto-width {
  display: inline-block;
  width: auto !important;
  margin-top: 0 !important;
}

.eventImg {
  width: 95%;
  height: auto;
  max-width: 600px;
  border: 1px solid #ddd;
  padding: 12px;
}

.time-slot {
  height: 12vw;
  max-height: 150px;
  background-color: white;
}

.dayTitle {
  font-size: 5vw;
}

.time {
  background-color: rgb(165, 50, 53);
  color: white;
  font-size: 2.2vw;
}

.classTitle {
  font-size: 2.2vw;
}

.classType {
  font-size: 1.4vw;
}

.current-day-border {
  border: 2px solid black;
  border-radius: 5px;
}

.dayBG {
  background-color: blue;
}

@media (min-width: 640px) {
  .dayTitle {
    font-size: 32px;
  }
}

@media (min-width: 727px) {
  .time {
    font-size: 16px;
  }
}

@media (min-width: 1142px) {
  .classType {
    font-size: 16px;
  }
}

@media (min-width: 1363px) {
  .classTitle {
    font-size: 30px;
  }
}
