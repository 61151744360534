@import "../../variables/variables.scss";
.footer-sec {
  background: $primarycolor;
  padding: 50px 0px 20px 0px;
  color: $lightbg;

  a {
    color: $white;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    padding-left: 11px;
    padding-right: 11px;
    text-decoration: none;
  }

  .social-icons {
    padding: 15px 0px;
    border-bottom: 1px solid;
    border-top: 1px solid;
    margin: 50px 0px 20px 0px;
    svg {
      font-size: 30px;
      margin: 10px;
      color: $lightbg;
    }
  }
}

// translator selection list start
.lang-change {
  margin: 40px 0 10px;
  padding: 5px;
}

#google_translate_element select {
  background: $primarycolor;
  color: white;
  border-radius: 3px;
  border: 1px solid white;
  padding: 10px 30px;
  font-weight: bold;
  cursor: pointer;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

.goog-te-banner-frame {
  display: none !important;
}

#goog-gt-tt,
.goog-te-balloon-frame {
  display: none !important;
}
.goog-text-highlight {
  background: none !important;
  box-shadow: none !important;
}
//google translator list end
