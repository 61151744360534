// @import "../../variables/variables.scss";
// .hero-sec {
// 	height: 700px;
// 	img {
// 		width: 100%;
// 		height: 100%;
// 		object-fit: cover;
// 	}
// }

// /* media query style start here 	*/
// @media (max-width: $larger-device) {
// 	.hero-sec {
// 		height: auto;
// 	}
// }

/* Set up the container to be full height and position relative for overlay content */
.hero-sec {
  position: relative;
  height: calc(100vh - 110px);
  width: 100%;
  overflow: hidden;
  background: black;
  z-index: -2;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Fullscreen video styling */
.video-background {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  object-fit: contain;
  z-index: -1; /* Ensure video is behind the content */
  background: black;
  max-width: 1200px;
}

/* Styling for the content overlay */
// .content {
//   position: relative;
//   z-index: 1; /* Ensure content is above the video */
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   color: white;
//   text-align: center;
//   padding: 20px;
// }

@media (min-width: 513px) {
  .video-background {
    object-fit: cover;
  }
}
