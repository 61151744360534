@import "../../variables/variables.scss";
.contact-form {
  h2 {
    @include h2-style;
    text-align: center;
  }
  .form-group input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #dedede;
    border-radius: 4px;
    &:focus-visible {
      outline: $primarycolor;
    }
    &::-internal-autofill-selected {
      background-color: gray !important;
    }
  }
  .submit-btn {
    @include button-style;
    margin-top: 30px;
  }
  textarea.form-control {
    height: 130px;
    resize: none;
    margin-bottom: 20px;
    border: 1px solid #dedede;
  }
  .contactus {
    padding: 50px;
    max-width: 800px;
    margin: auto;
    box-shadow: 0px 0px 30px 6px #e1dfdf;
    border-radius: 10px;
  }
}

/* media query style start here 	*/
@media (max-width: $small-device) {
  .contact-form .contactus {
    padding: 20px;
  }
}
